import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Img from "gatsby-image"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: `#fe66cb`,
    fontSize: `2rem`,
    fontWeight: `900`,
  },
  titleBar: {
    background: `rgba(16, 16, 17, 0.8)`,
    textAlign: 'left',
  },
  img: {
    display: `block`,
    height: `100%`,
    width: `100%`,
  },
  tile: {
    transition: `all 0.3s ease`,
    aspectRatio: '9 / 16',
    position: 'relative',
    '&:hover': {
      transform: `scale(1.05)`,
      cursor: `pointer`
    }
  },
  tileClicked: {
    position: `absolute`,
    height: `800px !important`,
    paddingRight: `25px`,
    transition: `all 0.3s ease`,
    width: `100%`,
    backgroundColor: `black`,
    zIndex: 1000,
    maxHeight: `800px`,
    display: `flex -webkit-box -ms-flexbox`,
    flexDirection: `row`,
    msFlexDirection: `row`,
    WebkitFlexDirection: `normal`
  },
  teacherContainer: {
    zIndex: 999,
    color: `white`
  },
  btn: {
    backgroundColor: 'transparent',
    padding: '0',
    borderStyle: 'none',
  }
}));

const StaffImgTile = ({ img, onClick }) => {
  const classes = useStyles()

  const imgSrc = img.childImageSharp.fluid.src
  const imgTitle = imgSrc.substring(imgSrc.lastIndexOf('_') + 1, imgSrc.lastIndexOf('.'))

  return (
    <>
      <button className={classes.btn} onClick={() => onClick(imgTitle, img)}>
        <article className={classes.tile}>
          <Img fluid={img.childImageSharp.fluid}  className={classes.img}  />
          <GridListTileBar
            title={imgTitle}
            classes={{
              root: classes.titleBar,
              title: classes.title,
            }}
          />
        </article>
      </button>
    </>
  );
}

export default StaffImgTile