import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import StaffImgTile from './staffImgTile'
import Modal from '@material-ui/core/Modal'
import Img from 'gatsby-image'
import Grid from '@material-ui/core/Grid'
import TeacherModalBody from './teacherModalBody'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Fade from '@material-ui/core/Fade'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
  },
  gridList: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  },
  gridListNoScroll: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    backgroundColor:  `rgb(240,229,215)`,
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    overflow: `hidden`
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
  //  background: `linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)`,
  },
  img: {
    display: `block`
  },
  tile: {
    height: `800px !important`
  },
  paper: {
    position: 'absolute',
    width: `70%`,
    backgroundColor: `rgb(16,16,17)`,
    boxShadow: theme.shadows[5],
    top: `15%`,
    left: `15%`,
    outline: 0,
    [theme.breakpoints.down('md')]: {
      top: `5%`,
    },
    [theme.breakpoints.down('sm')]: {
      width: `70%`,
      top: `5%`,
      left: `15%`,
    },
    [theme.breakpoints.down('xs')]: {
      width: `100%`,
      top: `0%`,
      left: `0%`,
    },
  },
  teacherImg: {
    height: `100%`
  },
  modal: {
    overflow: `scroll`,
    zIndex: `1500 !important`
  },
  teacherImgWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
    },
    [theme.breakpoints.down('xs')]: {
      width: `100%`,
    }
  },
  closeModalBtn: {
    margin: `10px`,
    borderRadius: `0px`,
    backgroundColor: `#fe3324`,
    boxShadow: '8px 6px #fe66cb',
    zIndex: 999,
    position: `absolute`
  },
  backdropRoot: {
    backgroundColor: `rgba(0, 0, 0, 0.8) !important`
  }
}));

const StaffRoll = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: {
            regex: "/(jpg)|(jpeg)|(png)/"}, 
            relativeDirectory: {
              eq: "staff"
            }
          },
        sort: {
          fields: name,
          order: ASC
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(width: 500, height: 800) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const { edges: images } = data.allFile
  const [open, setOpen] = useState(false);
  const [teacherName, setTeacherName] = useState(``)
  const [modalImg, setModalImg] = useState()

  const handleOpen = (teacherName, img) => {
    setTeacherName(teacherName)
    setModalImg(img)
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  let modalBody = (
    <div className={classes.paper}>
      <Grid container spacing={0}>
        <Grid item sm={12} md={4} className={classes.teacherImgWrapper}>
          {modalImg &&
            <>
              <Hidden smUp>
                <Button variant="contained" onClick={() => setOpen(false)} className={classes.closeModalBtn}>
                  <CloseIcon />
                </Button>
              </Hidden>
              <Img fluid={modalImg.childImageSharp.fluid} alt={`${teacherName}'s teacher image`} className={classes.teacherImg} />
            </>
          }
        </Grid>
        <Grid item sm={12} md={8} style={{display: `flex`}}>
          <TeacherModalBody teacherName={teacherName} />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      <Container>
        <div
          className={classes.gridList}>
            {images && images.map(({ node: img }, i) => (
              <StaffImgTile img={img} key={i} onClick={handleOpen} />
            ))}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="teacher-modal"
          aria-describedby="teacher-name-and-bio"
          className={classes.modal}
          BackdropProps={{
            className: classes.backdropRoot
          }
          }
        >
          <Fade in={open}>
            {modalBody}
          </Fade>
        </Modal>
      </Container>
    </div>
  );
}

export default StaffRoll