import React from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    paddingTop: `25px`,
    paddingBottom: `25px`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-evenly`
  },
  modalName: {
    color: `white`
  },
  modalPosition: {
    color: `white`,
  },
  modalBody: {
    color: `white`
  },
  modalQuote: {
    color: `rgb(222,174,239)`
  }, 
  links: {
    color: `white`,
    '&:hover': {
      color: `rgb(222,174,239)`
    }
  }
}));

const TeacherModalBody = ({teacherName}) => {
  const classes = useStyles()

  const {
    name,
    position,
    bio,
    quote,
    website,
    instagram
  } = teacherInfo[teacherName];

  return (
    <Container classes={{root: classes.containerRoot}}>
      <div>
        <Typography variant="h2" component="h2" align="center" className={classes.modalName}>
          {name}
        </Typography>
        <Typography variant="h4" component="h4" align="center" className={classes.modalPosition} gutterBottom>
          {position}
        </Typography>
      </div>
      <Typography align="left" className={classes.modalBody} gutterBottom>
        {bio}
        {instagram ? 
          <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer" className={classes.links}>@{instagram}</a>
        : null}
        {website ? ` || ` : null}
        {website ?
          <a href={`https://${website}`} target="_blank" rel="noreferrer" className={classes.links}>{website}</a>
        : null}
      </Typography>
      <Typography variant="h4" component="h4" align="center" className={classes.modalQuote} gutterBottom>
        {quote}
      </Typography>
    </Container>
  )
}

export default TeacherModalBody

const teacherInfo = {
  MOLLY: {
    name: `Molly Dunn`,
    position: `Founder and Director • Head of Broadway Studio`,
    bio: `Molly Dunn has had a unique career in both musical theatre and opera. Most recently, Molly performed Suor Angelica at the Kammeroper in Vienna, Austria and Mimì in La Bohème with the International Summer Festival of Morelia. Her favorite musical theatre credits include SHE LOVES ME, BEAUTY AND THE BEAST, THE MYSTERY OF EDWIN DROOD, PHANTOM, and SUNDAY IN THE PARK WITH GEORGE. She is a Lotte Lenya Competition Finalist and Special Award Winner. Molly holds a Masters in Music from NYU and is on faculty at CAP21/Molloy College. She has been teaching young performers for over a decade and created LUX performing arts to give young performers a theatre home and a safe space to be 100% themselves. To keep up to date with Molly’s singing adventures: `,
    quote: `"Sing like everyone is watching!"`,
    website: `molly-dunn.com`,
    instagram: `mollysdunn`
  },
  CAT: {
    name: `Cat Rubenis- Stevens`,
    position: `Assistant Director • Acting & Film • Musical Theatre`,
    bio: `Catriona Rubenis-Stevens moved to New York from England a decade ago, and has worked as a director, choreographer, producer and teaching artist. Her films have screened around the world, and have garnered numerous awards at festivals including: The New York International Film Festival, The Amsterdam Film Festival, BBC, ALBA in Glasgow and the Berlin International Film Awards, and have been in consideration by the Academy of Motion Picture Arts and Sciences for the Oscars. Her latest film, "e.ro.sion, noun", is currently in the festival circuit. She attended the American Academy of Dramatic Arts, and holds BA at New Jersey City University.`,
    quote: `"If you risk nothing, you risk everything."`
  },
  TIA: {
    name: `Tia Dionne Hodge-Jones`,
    position: `Head of Acting & Film Studio`,
    bio: `Actor, writer, director/producer Tia Dionne Hodge has coached and directed young actors and filmmakers for nearly 25 years. She is a member of SAG-AFTRA, AEA, NYWIFT, and Dramatist Guild.`,
    quote: `"Prepare. Pretend. Be true. Repeat."`
  },
  SUZANNE: {
    name: `Suzanne Fiore`,
    position: `Musical Theatre • Voice • Studio Manager`,
    bio: `Suzanne is a NYC native, currently residing in Jersey City. She is a proud graduate of LaGuardia H.S. of Music and Art and the Performing Arts and the Boston Conservatory. She has performed as a soloist with the Boston Pops Orchestra, and as a soloist at the Rose Theatre in Jazz at Lincoln Center & Carnegie Hall. She toured the country with the national tour of Ragtime and Cameron Machintosh's Oliver! She has been nominated for a MAC (Manhattan Association of Cabarets and Clubs) Award for Best Female Cabaret Debut and continues to produce solo shows. Most recently, at The Laurie Beechman Theatre, she premiered her CABARET PHOTO GALLERY project. Suzanne has taught early music education classes for over a decade and she also owns a NYC/JC based Photography Business.`,
    quote: `"Express yourself. Nobody else can."`
  },
  KIMMI: {
    name: `Kimmi Neuschulz`,
    position: `Musical Theatre • Voice • Social Media`,
    bio: `Kimmi Neuschulz is a recent graduate of Ithaca College’s BFA Musical Theatre program. She's a Filipino-American actor, singer, dancer, and choreographer based in New York City, and she's very passionate about being part of new works that are inclusive of plus-size and Asian-American artists. Her recent credits include Margaret in The Light in the Piazza, Emma Goldman in Ragtime, and Yoonji in Hookman. Back in March, she had her senior showcase in NYC and made her 54 Below debut. Separate from her life as a teacher and an artist, she loves cows, embroidery, and watching Survivor!`,
    quote: `"Where words fail, music speaks."`
  },
  DAN: {
    name: `Dan Corica`,
    position: `Voice • Piano • Songwriting`,
    bio: `Dan Corica is an NYC-based voice teacher, coach, and performer. As a teacher, he has held appointments as an adjunct instructor at NYU Steinhardt and currently teaches in the B.F.A Theater Arts program at Molloy College/CAP21. He also maintains an active private studio working with aspiring theater professionals and hobbyists alike, and has served as a vocal coach for high school musical theater productions across New Jersey. As a performer, Dan has appeared across the country with TheaterworksUSA and in NYC with brooklyn theater club, FringeNYCJr, and at historic venues like The Bitter End and Town Hall. Dan is a graduate of Princeton University and holds a masters degree from NYU Steinhardt's program in Vocal Performance and Pedagogy.`,
    quote: `"Just be who you wanna be!"`
  },
  NICK: {
    name: `Nick Nesbitt`,
    position: `Musical Theatre • Voice`,
    bio: `Tenor, Nicholas Nesbitt most recently performed Tonio in Donizetti's LA FILLE DU REGIMENT. Favorite musical theater credits include THE SECRET GARDEN, PIPPIN, and GODSPELL. He recently completed his Doctoral coursework at Indiana University where he also received a certificate in Vocology. He has won major awards from the Bel Canto Foundation, the National Society of Arts and Letters, and NATS state and regional competitions. Nick is thrilled to be teaching with LUX performing arts!`,
    quote: `"Let your freak flag fly!"`
  },
  MEGAN: {
    name: `Megan Magden Postle`,
    position: `Musical Theatre • Voice`,
    bio: `Megan Magden Postle has worked with vocalists for over 18 years as a voice technician and coach. Megan has served as director, music director and choreographer as well as a professional performer for countless musicals and plays. Some of her favorites include; Into the Woods, Ragtime, West Side Story, The Music Man and assisting with the pilot program for Frozen, Jr. She holds a Masters of Arts from Teachers College, Columbia University, with a focus in vocal pedagogy and the young voice.`,
    quote: `"Your voice lives between your head and heart." - Chandler Thompson`
  },
  DILLON: {
    name: `Dillon Feldman`,
    position: `Musical Theatre • Voice `,
    bio: `Dillon Feldman is a composer and writer living in NYC. He has written for Halflight Productions on indie films e.ro.sion(noun), Deus Meus Adiuva Me, and more. He is a resident composer for Bookworm Theatrics, based out of NY/NJ. Recently, he has written for theatrical productions at Out Front Theatre Company, and designed original music for a podcast series with RYCO Theatricals. He also worked with award-winning playwright, Ty Autry, to write and record new music for his play, Southern Fairytale. Dillon's original musical, Siren, is currently in development in association with the Shubert Organization in NYC.`
  },
}