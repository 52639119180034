import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import StaffRoll from '../components/staffRoll'

const useStyles = makeStyles((theme) => ({
  sandwich: {
    height: `0px`,
    transform: `translateY(-35px)`,
    paddingLeft: `10%`,
    zIndex: 99
  },
  wrapper: {
    backgroundColor: `rgb(240,229,215)`,
    marginTop:  `-1rem`,
    paddingBottom: `2rem`,
    flex: `1 1 auto`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`

  },
  polkas: {
    paddingTop: `3rem`,
    backgroundImage: `radial-gradient(rgba(230,244,251, 0.5) 20%, transparent 20%), radial-gradient(rgba(230,244,251, 0.5) 20%, transparent 20%)`,
    backgroundColor: `rgb(208,232,245)`,
    backgroundPosition: `0 0, 50px 50px`,
    backgroundSize: `100px 100px`
  },
  staffContainer: {
    transition: `all 0.3s ease`,
  },
  donateLink: {
    fontWeight: `bold`,
    color: theme.palette.primary.main
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title="About" description="Formerly known as Singing with Molly, LUX brings the top performing arts educators to Jersey City. We are a collective of singers, songwriters, actors, musicians, and artists." />
      <Box className={classes.polkas}>
        <Container maxWidth="lg">
          <Typography variant="h2" component="h2" align="center" gutterBottom>About LUX</Typography>
          <Typography variant="h6" component="h6" gutterBottom>
            Formerly known as Singing with Molly, LUX brings the top performing arts educators to Jersey City. We are a collective of singers, songwriters, actors, musicians, and artists. We love sharing our passion for the arts with young talent and witnessing their journeys towards expressing themselves and finding confidence in their artistic voices.
          </Typography>
          <Typography variant="h6" component="h6" gutterBottom>
            We are here to support you and see you fly!
            Please consider donating to our <a href={`https://fundraising.fracturedatlas.org/lux-performing-arts`} target='_blank' rel='noreferrer' className={classes.donateLink}>LUX scholarship fund</a>
          </Typography>
        </Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170">
          <path fill="rgb(240,229,215)" fillRule="nonzero" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1440 320 L 1320 320 C 1200 320 960 320 720 320 C 480 320 240 320 120 320 L 0 320 Z"></path>
          <path fill="rgb(239,178,91)" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
          <path className="second-path" fill="rgb(242,203,144)" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
          <path className="third-path" fill="rgb(244,236,209) " fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
        </svg>
      </Box>
      <Box component="section" className={classes.wrapper}>
        <Container maxWidth='xl' className={classes.staffContainer}>
          <StaffRoll />
        </Container>
      </Box>
    </Layout>
  )
}

export default About
